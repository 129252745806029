import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/PTZ_Tour/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SetPtzPerm';
import PTZTourTable from 'components/Web_User_Interface/1440p_Series/Features/PTZ_Tour/ptzTourTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // PTZ Tour",
  "path": "/Web_User_Interface/1440p_Series/Features/PTZ_Tour/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.",
  "image": "./WebUI_1440p_SearchThumb_Features_PTZ_Tour.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // PTZ Tour' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.' image='/images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/PTZ_Tour/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/PTZ_Tour/' crumbLabel="PTZ Tour" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efd149967cc2c9a388821384ee99d379/b97f6/1440p_Settings_Features_PTZ_Tour.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7rAAAO6wFxzYGVAAACRklEQVQoz22SS2/TQBRG+y+oKGra8mhjOyI0KVVFmofTQJu6tmfsGdtp0icCsakEK1aAigAhlS74B4DYwLJBSPy5g/xALZTFke/MSEffvdcTlmVhGGWs24vYh+9Ze/KR1sMPNA9PaBz8h/2T7K37+BRxdEqjscrk5BWmpqaYmZlhIhWWywtUqnWSV98ZvvvJ4M2Y4dsf6OMx6visYFycx0Svzzg4+cXo5VdWVhrcvHEL07CYn19gwjAMUkzDwHf7DKKAOBQkSpLonFgVZyWyOkUHPlv9dRr3OrSaXdqtHrXaXSZM08yFloWQkmSwTRTHRFGcfeMkIY5zlNaEocrRGiECup01Ws1cWltcuiA0TXzfRylFEARZLYRPGAaEYZijVPau0rtkD6kTnI02nbb9t7BcLmctCyGIs3QRcRQRJXlaKeUl0nRK+eiwQ2u1nQnract/Zpgux3EcXNfNxJFW6NEjou1ddFprfQkV+nj9Fu1mm57dYal+MaFpsulsZlLP87O2gu1DVDxEq/CSLFSagQ4I+jZ2q5kJa7X6Pwn7Dq7j5rNMhckuYTRAhWE+uwKtFDII2YsVzwYu3U6HNbtHvb6c/4dpOnPBxN13kXsS6UmEFIhiMekIhO8iPBdf+LhC4Kaz9D2kvUztTpVqdRGjbJ5v2TIt3MBFKpkJzhcgsjQyGiHjHXSgGXk+OzLA2djg6rVppqdzSqXSecuVSgVvy0OKQigKoe8hkn3U80+IF984Gj7li93j87rL7v0HlK7PMTc7y2zBb5d0xDEa7IjyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efd149967cc2c9a388821384ee99d379/e4706/1440p_Settings_Features_PTZ_Tour.avif 230w", "/en/static/efd149967cc2c9a388821384ee99d379/d1af7/1440p_Settings_Features_PTZ_Tour.avif 460w", "/en/static/efd149967cc2c9a388821384ee99d379/7f308/1440p_Settings_Features_PTZ_Tour.avif 920w", "/en/static/efd149967cc2c9a388821384ee99d379/44a65/1440p_Settings_Features_PTZ_Tour.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efd149967cc2c9a388821384ee99d379/a0b58/1440p_Settings_Features_PTZ_Tour.webp 230w", "/en/static/efd149967cc2c9a388821384ee99d379/bc10c/1440p_Settings_Features_PTZ_Tour.webp 460w", "/en/static/efd149967cc2c9a388821384ee99d379/966d8/1440p_Settings_Features_PTZ_Tour.webp 920w", "/en/static/efd149967cc2c9a388821384ee99d379/cb8de/1440p_Settings_Features_PTZ_Tour.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efd149967cc2c9a388821384ee99d379/81c8e/1440p_Settings_Features_PTZ_Tour.png 230w", "/en/static/efd149967cc2c9a388821384ee99d379/08a84/1440p_Settings_Features_PTZ_Tour.png 460w", "/en/static/efd149967cc2c9a388821384ee99d379/c0255/1440p_Settings_Features_PTZ_Tour.png 920w", "/en/static/efd149967cc2c9a388821384ee99d379/b97f6/1440p_Settings_Features_PTZ_Tour.png 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efd149967cc2c9a388821384ee99d379/c0255/1440p_Settings_Features_PTZ_Tour.png",
              "alt": "Web User Interface - 1440p Series - Features PTZ Tour",
              "title": "Web User Interface - 1440p Series - Features PTZ Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTourTable mdxType="PTZTourTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`To start your tour return to the live video and open the preset positions menu above the video. Clicking on `}<strong parentName="p">{`Tour`}</strong>{` will start the tour for you:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b70b579103e8d01710582d44c1129706/748b0/1080p_Settings_Features_PTZ_Tour_b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.478260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAp0lEQVQI13XMLwvCQBQA8H3ElRVhcGV1weg3ELsiHvtTFgQFFWzaLCaLKJcU29JtuO29u3t7ot3fB/h55Cw5stYaa51z/Eff98yslCqKIgiCMAx93/daMB0YAISuRUQi90VExjAzbLbufmNm+r1KKSmlECKO4yiKvMnuOVq9DqfLYz+eyjzP0ixNZov0vJnzcYjJgJYCruvqDXWlm6ZBxLqutdZlWX4A1imV/EMmyf8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b70b579103e8d01710582d44c1129706/e4706/1080p_Settings_Features_PTZ_Tour_b.avif 230w", "/en/static/b70b579103e8d01710582d44c1129706/d1af7/1080p_Settings_Features_PTZ_Tour_b.avif 460w", "/en/static/b70b579103e8d01710582d44c1129706/67bb0/1080p_Settings_Features_PTZ_Tour_b.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b70b579103e8d01710582d44c1129706/a0b58/1080p_Settings_Features_PTZ_Tour_b.webp 230w", "/en/static/b70b579103e8d01710582d44c1129706/bc10c/1080p_Settings_Features_PTZ_Tour_b.webp 460w", "/en/static/b70b579103e8d01710582d44c1129706/1ae05/1080p_Settings_Features_PTZ_Tour_b.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b70b579103e8d01710582d44c1129706/81c8e/1080p_Settings_Features_PTZ_Tour_b.png 230w", "/en/static/b70b579103e8d01710582d44c1129706/08a84/1080p_Settings_Features_PTZ_Tour_b.png 460w", "/en/static/b70b579103e8d01710582d44c1129706/748b0/1080p_Settings_Features_PTZ_Tour_b.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b70b579103e8d01710582d44c1129706/748b0/1080p_Settings_Features_PTZ_Tour_b.png",
              "alt": "Web User Interface - 1440p Series - Features PTZ Tour",
              "title": "Web User Interface - 1440p Series - Features PTZ Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      